<template>
  <div class="request-partner-container">
    <!-- Filter Tabs -->
    <div class="filter-tabs">
      <button
        v-for="filter in filters"
        :key="filter.value"
        :class="[
          'filter-tab',
          filter.value,
          { active: currentFilter === filter.value }
        ]"
        @click="changeFilter(filter.value)"
      >
        <i :data-feather="filter.icon" />
        {{ filter.label }}
        <span
          v-if="filter.count !== undefined"
          class="count"
        >({{ filter.count }})</span>
      </button>
    </div>
    <!-- Search Inputs Section -->
    <div class="search-input-wrapper">
      <label for="username-search">Recherche</label>
      <div class="search-input-container">
        <i
          data-feather="search"
          class="search-icon"
        />
        <input
          id="username-search"
          v-model="searchParams.username"
          placeholder="Recherche"
          class="search-input"
          @input="debouncedSearch"
        >
      </div>
    </div>
    <!-- Loading State -->
    <div
      v-if="loading"
      class="loading"
    >
      <div class="spinner" />
      <p>Chargements des Demandes...</p>
    </div>

    <!-- Error State -->
    <div
      v-else-if="error"
      class="error-container"
    >
      <i data-feather="alert-circle" />
      <p>{{ error }}</p>
      <button
        class="retry-button"
        @click="fetchRequestPartners"
      >
        <i data-feather="refresh-cw" />
        Retry
      </button>
    </div>

    <!-- Data Table -->
    <div
      v-else
      class="table-container"
    >
      <table
        v-if="requestPartners.length"
        class="table"
      >
        <thead>
          <tr>
            <th>Nom</th>
            <th>Email</th>
            <th>Numéro téléphone</th>
            <th>Message</th>
            <th>Status</th>
            <th v-if="currentFilter !== 'accepted' && currentFilter !== 'canceled'">
              Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="partner in requestPartners"
            :key="partner.id"
          >
            <td>{{ partner.username }}</td>
            <td>{{ partner.email || 'N/A' }}</td>
            <td>{{ partner.phone_number }}</td>
            <td class="message-cell">
              <div class="message-content">{{ partner.message || 'No message' }}
              </div>
            </td>
            <td>
              <span :class="['status-badge', getStatusClass(partner)]">
                {{ getStatusText(partner) }}
              </span>
            </td>
            <td
              v-if="currentFilter !== 'accepted' && currentFilter !== 'canceled'"
              class="actions-cell"
            >
              <button
                v-if="!partner.is_accepted && !partner.is_canceled"
                :disabled="loading || isConverting"
                class="btn-accept"
                @click="acceptRequest(partner.id)"
              >
                <i data-feather="check-circle" />
                {{ isConverting ? 'Converting...' : 'Accept' }}
              </button>
              <button
                v-if="!partner.is_accepted && !partner.is_canceled"
                :disabled="loading"
                class="btn-cancel"
                @click="cancelRequest(partner.id)"
              >
                <i data-feather="x-circle" />
                Cancel
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Empty State -->
      <div
        v-else
        class="empty-state"
      >
        <i data-feather="inbox" />
        <p>Aucune demande {{ currentFilter }} trouvée. </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import feather from 'feather-icons'

export default {
  name: 'RequestPartnerList',
  data() {
    return {
      requestPartners: [],
      loading: true,
      error: null,
      currentFilter: 'pending',
      isConverting: false,
      searchParams: {
        username: '',
        email: '',
        phone: '',
      },
      filters: [
        { label: 'Tous Les Demandes', value: 'all', icon: 'list' },
        { label: 'Pending', value: 'pending', icon: 'clock' },
        { label: 'Accepté', value: 'accepted', icon: 'check-circle' },
        { label: 'Refusé', value: 'canceled', icon: 'x-circle' },
      ],
      counts: {
        all: 0,
        pending: 0,
        accepted: 0,
        canceled: 0,
      },
    }
  },
  mounted() {
    this.fetchRequestPartners()
  },
  updated() {
    feather.replace()
  },
  methods: {
    async fetchRequestPartners() {
      this.loading = true
      this.error = null

      try {
        let endpoint = '/api/auth/request-partners/'
        // Prepare query parameters
        const params = {}

        // Combine search parameters into a single search term
        const searchTerms = []
        if (this.searchParams.username) searchTerms.push(this.searchParams.username)
        if (this.searchParams.email) searchTerms.push(this.searchParams.email)
        if (this.searchParams.phone) searchTerms.push(this.searchParams.phone)

        // If any search terms exist, add them as a single search parameter
        if (searchTerms.length > 0) {
          params.search = searchTerms.join(' ')
        }

        // Add filter for current tab
        if (this.currentFilter !== 'all') {
          endpoint += `${this.currentFilter}/`
        }

        const response = await axios.get(endpoint, {
          params,
        })

        this.requestPartners = response.data.results || response.data
        await this.updateCounts()
        this.loading = false
        this.$nextTick(() => {
          feather.replace()
        })
      } catch (error) {
        this.error = 'Failed to fetch request partners. Please try again.'
        console.error('Error fetching request partners:', error)
        this.loading = false
      }
    },

    // Add a debounced search method
    debouncedSearch() {
      // If you want to add debounce, you can use lodash or implement a simple debounce
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.fetchRequestPartners()
      }, 300) // 300ms delay
    },

    async updateCounts() {
      try {
        const counts = await Promise.all([
          axios.get('/api/auth/request-partners/', {
          }),
          axios.get('/api/auth/request-partners/pending/', {
          }),
          axios.get('/api/auth/request-partners/accepted/', {
          }),
          axios.get('/api/auth/request-partners/canceled/', {
          }),
        ])

        this.counts = {
          all: counts[0].data.count || counts[0].data.length,
          pending: counts[1].data.count || counts[1].data.length,
          accepted: counts[2].data.count || counts[2].data.length,
          canceled: counts[3].data.count || counts[3].data.length,
        }

        // Update filters with counts
        this.filters = this.filters.map(filter => ({
          ...filter,
          count: this.counts[filter.value],
        }))
      } catch (error) {
        console.error('Error updating counts:', error)
      }
    },

    async changeFilter(filter) {
      this.currentFilter = filter
      await this.fetchRequestPartners()
    },

    async acceptRequest(id) {
      // Prevent multiple submission
      if (this.isConverting) return

      try {
        this.isConverting = true

        // Call the conversion API
        const response = await axios.post(`/api/auth/convert-partner-to-supplier/${id}/`, {}, {
        })

        // If conversion is successful, extract the new supplier's user ID
        const supplierId = response.data.user_id

        // Redirect to the supplier edit page
        this.$router.push(`/suppliers/edit/${supplierId}`)

        // Optional: Show success toast
        this.$toast.success('Partenaire converti avec succès en fournisseur')
      } catch (error) {
        // Show error toast
        this.$toast.error(error.response?.data?.message || 'Failed to convert partner')
      } finally {
        this.isConverting = false
      }
    },

    async cancelRequest(id) {
      try {
        await axios.put(`/api/auth/cancel-request-partner/${id}/`, {}, {
        })
        await this.fetchRequestPartners()
        this.$toast.success('Demande annulée avec succès')
      } catch (error) {
        this.$toast.error('Échec annulation de la demande')
      }
    },

    getAuthToken() {
      return localStorage.getItem('authToken')
    },

    getStatusClass(partner) {
      if (partner.is_accepted) return 'status-accepted'
      if (partner.is_canceled) return 'status-canceled'
      return 'status-pending'
    },

    getStatusText(partner) {
      if (partner.is_accepted) return 'Accepté'
      if (partner.is_canceled) return 'Refusé'
      return 'Pending'
    },
  },
}
</script>

<style scoped>
.request-partner-container {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: 20px;
}

.filter-tabs {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  overflow-x: auto;
  padding-bottom: 8px;
}

.filter-tab {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: white;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.filter-tab:hover {
  background: #f5f5f5;
}

.count {
  font-size: 0.9em;
  opacity: 0.8;
}

.table-container {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;
}

.table th, .table td {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #444;
  text-align: left;
  position: sticky;
  top: 0;
}

.message-cell {
  max-width: 300px;
}

.message-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions-cell {
  white-space: nowrap;
  width: 200px;
}

.status-badge {
  padding: 6px 12px;
  border-radius: 12px;
  font-size: 0.9em;
  font-weight: 500;
}

.status-pending {
  background: #fff3dc;
  color: #b25e00;
}

.status-accepted {
  background: #e6f4ea;
  color: #1e8e3e;
}

.status-canceled {
  background: #fce8e6;
  color: #d93025;
}

.btn-accept,
.btn-cancel {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin: 0 4px;
  padding: 8px 16px;
  border: 2px solid transparent; /* Default transparent border */
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.9); /* Light background with opacity */
  color: #666; /* Neutral text color by default */
}

/* Pending state - grey contour and light grey background */
.btn-accept:not(:disabled),
.btn-cancel:not(:disabled) {
  border-color: #ccc; /* Grey contour */
  background-color: rgba(240, 240, 240, 0.7); /* Light grey with opacity */
}

/* Accepted button - green contour when selected */
.btn-accept:not(:disabled) {
  border-color: #1e8e3e; /* Green contour */
  background-color: rgba(30, 142, 62, 0.1); /* Light green with opacity */
  color: #1e8e3e; /* Green text */
}

/* Canceled button - red contour when selected */
.btn-cancel:not(:disabled) {
  border-color: #d93025; /* Red contour */
  background-color: rgba(217, 48, 37, 0.1); /* Light red with opacity */
  color: #d93025; /* Red text */
}

/* Hover states */
.btn-accept:not(:disabled):hover {
  background-color: rgba(30, 142, 62, 0.2);
}

/* Disabled state */
.btn-accept:disabled,
.btn-cancel:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-accept {
  background-color: #1e8e3e;
  color: white;
}

.filter-tab:not(.active) {
  border-color: #ccc; /* Grey contour */
  background-color: rgba(240, 240, 240, 0.7); /* Light grey with opacity */
}

.filter-tab.active {
  border-color: #1a73e8; /* Blue contour when selected */
  background-color: rgba(26, 115, 232, 0.1); /* Light blue with opacity */
  color: #1a73e8; /* Blue text */
}

.filter-tab.pending.active {
  border-color: #f0a00d;
  background-color: rgba(218, 185, 143, 0.267);
  color: #f0a00d;
}

.filter-tab.accepted.active {
  border-color: #1e8e3e;
  background-color: rgba(30, 142, 62, 0.1);
  color: #1e8e3e;
}

.filter-tab.canceled.active {
  border-color: #d93025;
  background-color: rgba(217, 48, 37, 0.1);
  color: #d93025;
}

.btn-accept:hover {
  background-color: #167c31;
}

.btn-cancel {
  background-color: #d93025;
  color: white;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #666;
}

.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 0 auto 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-container {
  text-align: center;
  padding: 40px;
  color: #d93025;
}

.retry-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  padding: 8px 16px;
  border: 1px solid #d93025;
  border-radius: 4px;
  background: white;
  color: #d93025;
  cursor: pointer;
  transition: all 0.2s ease;
}

.retry-button:hover {
  background: #fce8e6;
}

.empty-state {
  text-align: center;
  padding: 40px;
  color: #666;
}

.empty-state i {
  margin-bottom: 16px;
  color: #999;
}

.feather {
  width: 16px;
  height: 16px;
  stroke-width: 2;
}
/* Search inputs container */
.search-inputs-container {
  display: flex;
  gap: 16px;
  margin-bottom: 28px;
}

.search-input-wrapper {
  max-width: 300px; /* Add a max-width to prevent it from becoming too wide */
  margin-bottom: 24px;
}

.search-input-wrapper label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #666;
}

.search-input {
  width: 100%;
  padding: 10px 10px 10px 40px; /* Left padding to accommodate the icon */
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 16px;
  transition: all 0.2s ease;
}

.search-input:focus {
  outline: none;
  border-color: #E80000;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.1);
}
.search-input-container {
  position: relative;
  width: 100%; /* Ensure full width of the wrapper */
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  color: #999;
  pointer-events: none; /* Prevent icon from intercepting mouse events */
  z-index: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .search-inputs-container {
    flex-direction: column;
    gap: 12px;
  }
}
</style>
